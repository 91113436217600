import React from 'react';
import './DeleteIcon.scss';

const DeleteIcon = () => {
  return (
    <svg className='delete-icon' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d={`M17.4269 10.8123H22.5154C22.5154 10.1296 22.2473 9.47488 21.7702 8.99214C21.2931 8.5094 20.6459 
        8.2382 19.9712 8.2382C19.2964 8.2382 18.6492 8.5094 18.1721 8.99214C17.695 9.47488 17.4269 10.1296 17.4269 
        10.8123ZM15.5188 10.8123C15.5188 10.2207 15.6339 9.63497 15.8577 9.08844C16.0814 8.5419 16.4094 8.04531 16.8228 
        7.62701C17.2363 7.20871 17.7271 6.8769 18.2673 6.65052C18.8075 6.42413 19.3865 6.30762 19.9712 
        6.30762C20.5559 6.30762 21.1348 6.42413 21.675 6.65052C22.2152 6.8769 22.706 7.20871 23.1195 7.62701C23.5329 8.04531 
        23.8609 8.5419 24.0846 9.08844C24.3084 9.63497 24.4236 10.2207 24.4236 10.8123H31.7382C31.9913 10.8123 32.2339 
        10.914 32.4129 11.095C32.5918 11.2761 32.6923 11.5216 32.6923 11.7776C32.6923 12.0336 32.5918 12.2791 32.4129 
        12.4602C32.2339 12.6412 31.9913 12.7429 31.7382 12.7429H30.059L28.5707 28.3304C28.4565 29.5248 27.9067 30.6333 
        27.0284 31.4397C26.1502 32.2461 25.0066 32.6927 23.8206 32.6922H16.1217C14.9359 32.6924 13.7926 32.2457 12.9147
         31.4393C12.0367 30.6329 11.4871 29.5245 11.3729 28.3304L9.88328 12.7429H8.20409C7.95105 12.7429 7.70837 12.6412 
        7.52945 12.4602C7.35052 12.2791 7.25 12.0336 7.25 11.7776C7.25 11.5216 7.35052 11.2761 7.52945 11.095C7.70837 
        10.914 7.95105 10.8123 8.20409 10.8123H15.5188ZM18.063 16.9258C18.063 16.6698 17.9625 16.4243 17.7835 16.2433C17.6046 
        16.0622 17.3619 15.9605 17.1089 15.9605C16.8559 15.9605 16.6132 16.0622 16.4343 16.2433C16.2553 16.4243 16.1548 
        16.6698 16.1548 16.9258V26.5787C16.1548 26.8347 16.2553 27.0803 16.4343 27.2613C16.6132 27.4423 16.8559 
        27.544 17.1089 27.544C17.3619 27.544 17.6046 27.4423 17.7835 27.2613C17.9625 27.0803 18.063 26.8347 18.063
         26.5787V16.9258ZM22.8334 15.9605C22.5804 15.9605 22.3377 16.0622 22.1588 16.2433C21.9798 16.4243 21.8793
         16.6698 21.8793 16.9258V26.5787C21.8793 26.8347 21.9798 27.0803 22.1588 27.2613C22.3377 27.4423 22.5804 27.544 
        22.8334 27.544C23.0865 27.544 23.3291 27.4423 23.5081 27.2613C23.687 27.0803 23.7875 26.8347 23.7875 26.5787V16.9258C23.7875 
        16.6698 23.687 16.4243 23.5081 16.2433C23.3291 16.0622 23.0865 15.9605 22.8334 15.9605Z`}
      />
    </svg>
  );
};

export default DeleteIcon;
