import React from 'react';
import './EditIcon.scss';

const EditIcon = () => {
  return (
    <svg className='edit-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 49 49' fill='none'>
      <path
        d={`M32.649 12.25C32.0307 12.25 31.4123 12.4804 30.9395 12.9654L23.1072 20.7986L28.1994 
        25.8793L36.0316 18.0582C36.9895 17.1003 36.9895 15.5724 36.0316 14.6266L34.3706 12.9654C33.8978 
        12.4804 33.2794 12.25 32.649 12.25ZM21.9554 21.9506L13.9898 29.9172L14.8385 30.766C14.1959 31.3359 
        13.5776 31.9907 12.9593 32.6091C12.0136 33.567 12.0136 35.0948 12.9593 36.0406C13.905 36.9865 
        15.4326 36.9865 16.3904 36.0406C17.0088 35.4465 17.6513 34.7796 18.2333 34.1733L19.082 35.01L27.0476 27.0434`}
        fill='#F0924F'
      />
    </svg>
  );
};

export default EditIcon;
