import React from 'react';
import './Question.css';

const Question = ({ className }) => {
  return (
    <svg className={`question ${className}`} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.33203 11.334C7.33203 11.7022 7.63051 12.0007 7.9987 12.0007C8.36689 12.0007 8.66536 11.7022 8.66536 11.334C8.66536
      10.9658 8.36689 10.6673 7.9987 10.6673C7.63051 10.6673 7.33203 10.9658 7.33203 11.334ZM7.9987 1.33398C7.12322 1.33398 6.25631
      1.50642 5.44747 1.84145C4.63864 2.17649 3.90371 2.66755 3.28465 3.28661C2.03441 4.53685 1.33203 6.23254 1.33203 8.00065C1.33203
      9.76876 2.03441 11.4645 3.28465 12.7147C3.90371 13.3338 4.63864 13.8248 5.44747 14.1598C6.25631 14.4949 7.12322 14.6673 7.9987
      14.6673C9.76681 14.6673 11.4625 13.9649 12.7127 12.7147C13.963 11.4645 14.6654 9.76876 14.6654 8.00065C14.6654 7.12517 14.4929
      6.25827 14.1579 5.44943C13.8229 4.64059 13.3318 3.90566 12.7127 3.28661C12.0937 2.66755 11.3588 2.17649 10.5499 1.84145C9.74108
      1.50642 8.87418 1.33398 7.9987 1.33398ZM7.9987 13.334C5.0587 13.334 2.66536 10.9406 2.66536 8.00065C2.66536 5.06065 5.0587 2.66732
      7.9987 2.66732C10.9387 2.66732 13.332 5.06065 13.332 8.00065C13.332 10.9406 10.9387 13.334 7.9987 13.334ZM7.9987 4.00065C7.29145
      4.00065 6.61318 4.2816 6.11308 4.7817C5.77168 5.1231 5.5324 5.54754 5.41493 6.00755C5.32382 6.36429 5.63051 6.66732 5.9987
      6.66732C6.36689 6.66732 6.65208 6.35119 6.82859 6.02807C6.88897 5.91754 6.96523 5.81517 7.05589 5.72451C7.30594 5.47446 7.64508
      5.33398 7.9987 5.33398C8.35232 5.33398 8.69146 5.47446 8.94151 5.72451C9.19155 5.97456 9.33203 6.3137 9.33203 6.66732C9.33203
      7.84697 7.76651 7.85248 7.40562 9.33858C7.31873 9.69637 7.63051 10.0007 7.9987 10.0007C8.36689 10.0007 8.64988 9.68699 8.81016
      9.35552C9.26974 8.4051 10.6654 8.05957 10.6654 6.66732C10.6654 5.96007 10.3844 5.2818 9.88432 4.7817C9.38422 4.2816 8.70594 4.00065
      7.9987 4.00065Z'
        fill='#F0924F'
      />
    </svg>
  );
};

export default Question;
