import React from 'react';
import './LeftArrowIcon.scss';

const LeftArrowIcon = () => {
  return (
    <svg className='left-arrow-icon' xmlns='http://www.w3.org/2000/svg' width='18' height='15' viewBox='0 0 18 15' fill='none'>
      <path d={`M17 8.50391C17.5523 8.50391 18 8.05619 18 7.50391C18 6.95162 17.5523 6.50391 17 6.50391V8.50391ZM0.292892 
      6.7968C-0.0976315 7.18732 -0.0976315 7.82049 0.292892 8.21101L6.65685 14.575C7.04738 14.9655 7.68054 14.9655 8.07107 
      14.575C8.46159 14.1844 8.46159 13.5513 8.07107 13.1608L2.41421 7.50391L8.07107 1.84705C8.46159 1.45653 8.46159 0.823363 
      8.07107 0.432838C7.68054 0.0423141 7.04738 0.0423141 6.65685 0.432838L0.292892 6.7968ZM17 6.50391L1 6.50391V8.50391L17 
      8.50391V6.50391Z`} fill='#F0924F'/>
    </svg>
  );
};

export default LeftArrowIcon;